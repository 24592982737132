import * as React from "react";
import { Link } from "react-router-dom";

export default function Home() {
    return (
      <>
        <main>
          <h2>Future Home of Big Dog AI!</h2>
          <p>This is going to be epic!</p>
        </main>
        <nav>
          <Link to="/about">About</Link>
        </nav>
      </>
    );
  }
  
 